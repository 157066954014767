<template>
  <div>dashboard</div>
</template>

<script>
export default {
    name: 'dashboard',

}
</script>

<style>

</style>